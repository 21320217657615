
import { defineAsyncComponent } from '@vue/runtime-core';
import { Vue, Options } from 'vue-class-component';

@Options({
    components: {
        PageIntro: defineAsyncComponent(() => import('@/features/home-page/components/page-intro.vue')),
        PagePersons: defineAsyncComponent(() => import('@/features/home-page/components/page-persons.vue')),
        PageCall2Action: defineAsyncComponent(() => import('@/features/home-page/components/page-call-2-action.vue')),
    },
})
export default class HomePage extends Vue {}
